.storage-select {
  display: flex;
  gap: 10px;
}

.storage-select button {
  min-width: 10em;
}

.storage-select button .icon {
  display: block;
  text-align: center;
  font-size: 3em;
  line-height: 0;
}
