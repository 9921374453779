@media screen and (max-width: 961px) {
  .video-preview:not(.closed) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: black;
    display: flex;
  }
}

.video-preview>.scan-region-highlight {
  border: 2px solid goldenrod;
  border-radius: 10px;
}